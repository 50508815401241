/*
 * Filter Anything v1.1.0
 * https://github.com/80DAYS/Filter-Anything
 */
$(document).ready(function() {
    $('[data-filter]').each(function() {
        var $_filterElement = $(this);
        var $_filterItems = $_filterElement.find('.' + $_filterElement.data('filter-class'));

        // Step 1: assemble a full list of each
        var filterCategories = '';
        $_filterItems.each(function() {
            if (typeof $(this).data('filter-categories') !== 'undefined') {
                filterCategories += $(this).data('filter-categories') + '|';
            }
        });
        filterCategories = filterCategories.substring(0, filterCategories.length - 1); // remove trailing |
        filterCategories = filterCategories.split('|'); // make it an array
        filterCategories = unique(filterCategories); // remove duplicates

        // Step 2: create the filter buttons with an optional dropdown on mobile
        var hasfilterDropdown = false;
        var filterDropdown = '';
        var buttonClass = '';

        if (typeof $_filterElement.data('filter-controls-select-dropdown-mobile') !== 'undefined') {
            hasfilterDropdown = true;
            buttonClass = 'hide-for-small-only';
            filterDropdown = '<div class="filter-controls-dropdown show-for-small-only"><select autocomplete="off"><option value="all">All</option>';
        }

        var filterButtons = '<button class="button all on ' + buttonClass + '">All</button>';

        for (var category in filterCategories) {
            filterButtons += '<button class="button ' + buttonClass + '" data-filter-category="' + filterCategories[category] + '">' + filterCategories[category] + '</button>';

            if (hasfilterDropdown) {
                filterDropdown += '<option value="' + filterCategories[category] + '">' + filterCategories[category] + '</option>';
            }
        }

        $_filterElement.find('.' + $_filterElement.data('filter-controls')).append(filterButtons);

        if (hasfilterDropdown) {
            filterDropdown += '</select></div>';
            $_filterElement.find('.' + $_filterElement.data('filter-controls')).append(filterDropdown);
        }

        // Step 4: set up the button events
        var $_filterButtons = $_filterElement.find('.' + $_filterElement.data('filter-controls')).find('button');

        $_filterButtons.on('click', function() {
            $(this)
                .siblings()
                .removeClass('on');

            // Clear the "All" button
            if (!$(this).hasClass('all')) {
                $_filterButtons.filter('.on').removeClass('on');
                $(this).addClass('on');
                var filterCategory = $(this).data('filter-category');

                // Trigger event to allow other JS to interact with the filter change
                $_filterElement.trigger('filter-changed', filterCategory);
            } else {
                // All button has been clicked, so show all buttons and return
                $_filterButtons.removeClass('on');
                $_filterButtons.filter('.all').addClass('on');
                $_filterElement.find('.filter-controls-dropdown select').val('all');

                $_filterItems.fadeIn();

                // Trigger event to allow other JS to interact with the filter change
                $_filterElement.trigger('filter-changed', null);

                return;
            }

            // Only show the items that match the filter
            $_filterItems.each(function() {
                if (typeof $(this).data('filter-categories') !== 'undefined') {
                    /*if (
                        $(this)
                            .data('filter-categories')
                            .indexOf(filterCategory) > -1
                    ) {*/
                    if ( itemContainsFilter(filterCategory, $(this).data('filter-categories')) ) {
                        $(this).fadeIn();
                    } else {
                        $(this).fadeOut();
                    }
                }
            });

            // Update the mobile dropdown
            if ($(window).innerWidth() > 640 && hasfilterDropdown) {
                $_filterElement.find('.filter-controls-dropdown select').val($(this).data('filter-category'));
            }
        });

        // Step 5: Set up the events for the dropdown menu
        if (hasfilterDropdown) {
            $_filterElement.find('.filter-controls-dropdown select').on('change', function() {
                if ($(window).innerWidth() <= 640) {
                    if ($(this).val() === 'all') {
                        $_filterElement
                            .find('.' + $_filterElement.data('filter-controls'))
                            .find('button.all')
                            .trigger('click');
                    } else {
                        $_filterElement
                            .find('.' + $_filterElement.data('filter-controls'))
                            .find('button[data-filter-category="' + $(this).val() + '"]')
                            .trigger('click');
                    }
                }
            });
        }
    });
});

// Filter a list to only unique values
// https://stackoverflow.com/questions/12551635/jquery-remove-duplicates-from-an-array-of-strings
function unique(list) {
    var result = [];
    $.each(list, function(i, e) {
        if ($.inArray(e, result) == -1) result.push(e);
    });
    return result;
}

function itemContainsFilter(needle, haystack) {
    var haystackItems = haystack.split('|');
    
    for (var i=0; i < haystackItems.length; i++) {
        if ( needle === haystackItems[i] ) {
            return true;
        }
    }
    
    return false;
}
