$(document).ready(function () {
  initBooking();
});

function initBooking() {
  $(".header__book button.book-room").on("click", function () {
    loadDatepickerAndOpen("rooms");
  });

  $(".header__book button.events-enquiry").on("click", function () {
    loadDatepickerAndOpen("events");
  });

  $('a[href="#events-enquiry"]').on("click", function () {
    loadDatepickerAndOpen("events");
    return false;
  });
}

function loadDatepickerAndOpen(bookingType) {
  if (!$("body").hasClass("booking-init")) {
    $("body").addClass("booking-init");
    $("body").append('<div class="datepicker__overlay"></div>');

    var script = document.createElement("script");
    script.onload = function () {
      openBooking(bookingType);
    };
    script.src = "/assets/vendor/litepicker.js";

    document.head.appendChild(script);
  } else {
    openBooking(bookingType);
  }
}

function openBooking(bookingType) {
  $('#booking').foundation('open');

  $('.booking__tabs-list').each(function() {
    $(this).find('li').removeClass('open');
    $(this).find('li').first().addClass('open');
  });

  // Hotel picker used on the group widget only
  $('.booking__hotel-picker select').on('change', function () {
    // Hide the overlay
    $('.booking').addClass('active');

    $('.booking__tabs-list').hide();
    $('.booking__tabs-list--' + $(this).val()).show();

    $('.booking__main').hide();
    $('.booking__main--' + $(this).val()).show();
  });
  
  $('.booking__tabs button').on('click', function () {
    $('.booking__tabs li').removeClass('open');
    $(this).parent().addClass('open');
    
    $('.booking__type').hide();
    $('.booking__' + $(this).data('booking-type')).show();
  });
  
  if (bookingType == 'events') {
    $('button[data-booking-type^="event"]:visible').trigger('click');
  } else {
    $('button[data-booking-type^="rooms"]:visible').trigger("click");
  }
  
  // Custom mobile-only accordion
  $(".booking__accordion-button").on("click", function () {
    // Grab the container element (which would be used in the initial selector)
    var $selectorEl = $(this).parent("div");

    if ($(this).is(".on")) {
      $(this).next(".booking__type").slideUp();
      $(this).removeClass("on");
    } else {
      $selectorEl.children(".booking__type").slideUp();
      $selectorEl.children(".booking__accordion-button").removeClass("on");

      $(this).next(".booking__type").slideDown();
      $(this).addClass("on");
    }
  });

  $(".booking__quantity-up").on("click", function (e) {
    e.preventDefault();

    var $field = $(this).prev();
    var fieldValue = $field.val();

    if (fieldValue < $field.attr("max")) {
      fieldValue++;
      $field.val(fieldValue);
    }
  });

  $(".booking__quantity-down").on("click", function (e) {
    e.preventDefault();

    var $field = $(this).next();
    var fieldValue = $field.val();

    if (fieldValue > $field.attr("min")) {
      fieldValue--;
      $field.val(fieldValue);
    }
  });
  
  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  var openingDateString = '2024-02-05';
    
  var openingDate = new Date(openingDateString);
  var today = new Date();
  var tomorrow = new Date();
  var yesterday = new Date();

  if ( today < openingDate ) {
    today     = new Date(openingDateString);
    tomorrow  = new Date(openingDateString);
    yesterday = new Date(openingDateString);
  }
  
  tomorrow.setDate(tomorrow.getDate() + 1);
  yesterday.setDate(today.getDate() - 1);


  $('.booking__rooms').each(function () {
    var $roomBookingContainer = $(this);    
    
    var datepicker = new Litepicker({ 
      element: $roomBookingContainer.find('.datepicker')[0],
      parentEl: $('.datepicker__overlay')[0],
      minDate: yesterday,
      numberOfMonths: 2,
      numberOfColumns: 2,
      minDays: 2,
      singleMode: false,
      startDate: today,
      endDate: tomorrow,
      showTooltip: false
    });
    
    datepicker.on('hide', function () {
      updateDates();
      $('.datepicker__overlay').fadeOut();
    });
      
    updateDates();

    $roomBookingContainer.find('.booking__field--check-in, .booking__field--check-out').on('click', function () {
      if ( window.innerWidth < 640 ) {
        datepicker.setOptions({
          numberOfColumns: 1
        });
      }

      $('.datepicker__overlay').fadeIn();
      datepicker.show();
    });
    
    
    function updateDates() {
      var checkInDate = new Date(datepicker.getStartDate().dateInstance);
      var checkOutDate = new Date(datepicker.getEndDate().dateInstance);

      $roomBookingContainer
        .find(".booking__field--check-in .booking__field-date")
        .text(pad(checkInDate.getDate(), 2));
      $roomBookingContainer
        .find(".booking__field--check-in .booking__field-month-year")
        .text(
          monthNames[checkInDate.getMonth()] + " " + checkInDate.getFullYear()
        );
      $roomBookingContainer
        .find(".booking__field--check-in .booking__field-day")
        .text(dayNames[checkInDate.getDay()]);
      $roomBookingContainer
        .find('input[name="fromDate"]')
        .val(
          pad(checkInDate.getMonth() + 1, 2) +
            "/" +
            pad(checkInDate.getDate(), 2) +
            "/" +
            checkInDate.getFullYear().toString().substr(-2)
        );

      $roomBookingContainer
        .find(".booking__field--check-out .booking__field-date")
        .text(pad(checkOutDate.getDate(), 2));
      $roomBookingContainer
        .find(".booking__field--check-out .booking__field-month-year")
        .text(
          monthNames[checkOutDate.getMonth()] + " " + checkOutDate.getFullYear()
        );
      $roomBookingContainer
        .find(".booking__field--check-out .booking__field-day")
        .text(dayNames[checkOutDate.getDay()]);
      $roomBookingContainer
        .find('input[name="toDate"]')
        .val(
          pad(checkOutDate.getMonth() + 1, 2) +
            "/" +
            pad(checkOutDate.getDate(), 2) +
            "/" +
            checkOutDate.getFullYear().toString().substr(-2)
        );
    }
  });
  
  $(".form__datepicker").each(function () {
    var $dateField = $(this);

    var formDatepicker = new Litepicker({
      element: $dateField[0],
      parentEl: $(".datepicker__overlay")[0],
      minDate: yesterday,
      numberOfMonths: 1,
      numberOfColumns: 1,
      minDays: 1,
      singleMode: true,
      showTooltip: false,
    });

    formDatepicker.on("hide", function () {
      $(".datepicker__overlay").fadeOut();
    });

    $dateField.on("click", function () {
      $(".datepicker__overlay").fadeIn();
      formDatepicker.show();
    });
  });
  
}
